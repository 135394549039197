<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Products/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h5 class="my-1 text-uppercase">Basic Info</h5>
              </div>
              <div class="col-12 col-sm-6 col-md-9">
                <div class="form-group">
                  <label for="name">Product Name *</label>
                  <input v-model="postProductData.name" type="text" id="name" class="text-capitalize form-control"
                         placeholder="Enter Product Name">
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Source *</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedSource" class="multiselect-blue"
                                    :options="sourceOption"
                                    :close-on-select="true" placeholder="Select source" label="name"
                                    track-by="name"
                                    :show-labels="false"/>
                  </div>
                  <div class="text-danger" v-if="errors.source">{{ errors.source }}</div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Product Category *</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedProductCategory" class="multiselect-blue"
                                    :options="productCategoryOptions"
                                    :close-on-select="true" placeholder="Select Product Category" label="name"
                                    track-by="name"
                                    :show-labels="false"/>
                  </div>
                  <div class="text-danger" v-if="errors.productCategoryId">{{ errors.productCategoryId }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Product Type *</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedType" class="multiselect-blue"
                                    :options="typeOptions"
                                    :close-on-select="true" placeholder="Select Product Type" label="name"
                                    track-by="name"
                                    :show-labels="false"/>
                  </div>
                  <div class="text-danger" v-if="errors.type">{{ errors.type }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="productStatus" type="checkbox" class="custom-control-input" checked
                                 id="productStatus">
                          <label class="custom-control-label mr-1" for="productStatus"></label>
                        </div>
                        <span class="font-medium-1">{{ productStatus ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Pre Order</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="productPreOrder" type="checkbox" class="custom-control-input" checked
                                 id="preOrderStatus">
                          <label class="custom-control-label mr-1" for="preOrderStatus"></label>
                        </div>
                        <span class="font-medium-1">{{ productPreOrder ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.preOrder">{{ errors.preOrder }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Return Appointment Status</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="productReturnAppointmentStatus" type="checkbox" class="custom-control-input" checked
                                 id="returnAppointmentStatusId">
                          <label class="custom-control-label mr-1" for="returnAppointmentStatusId"></label>
                        </div>
                        <span class="font-medium-1">{{ productReturnAppointmentStatus ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.returnAppointmentStatus">{{ errors.returnAppointmentStatus }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <div class="controls">
                    <label>Customer Portal Status</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="productCustomerPortalStatus" type="checkbox" class="custom-control-input" checked
                                 id="customerPortalStatusId">
                          <label class="custom-control-label mr-1" for="customerPortalStatusId"></label>
                        </div>
                        <span class="font-medium-1">{{ productCustomerPortalStatus ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.customerPortalStatus">{{ errors.customerPortalStatus }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Description</label>
                  <QuillEditor v-model:content="postProductData.description" contentType="html"
                               toolbar="full"
                               theme="snow"
                               placeholder="Enter Description"/>
                  <div class="text-danger" v-if="errors.description">{{ errors.description }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h5 class="my-1 text-uppercase">Meta Info</h5>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="metaAuthor">Meta Author</label>
                  <input v-model="postProductData.meta_author" type="text" id="metaAuthor"
                         class="text-capitalize form-control"
                         placeholder="Enter Meta Author">
                  <div class="text-danger" v-if="errors.metaAuthor">{{ errors.metaAuthor }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="from-group">
                  <label>Meta Keywords</label>
                  <textarea v-model="postProductData.meta_keywords" class="form-control"
                            id="metaKeywords"
                            placeholder="Enter Meta keyword. Please, separated by comma">
                  </textarea>
                  <div class="text-danger" v-if="errors.metaKeywords">{{ errors.metaKeywords }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="from-group">
                  <label>Meta Description</label>
                  <textarea v-model="postProductData.meta_description" class="form-control"
                            id="metaDescription"
                            placeholder="Enter Meta Description, but not more than 250 Characters.">
                  </textarea>
                  <div class="text-danger" v-if="errors.metaDescription">{{ errors.metaDescription }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="from-group">
                  <label>Other Meta Data</label>
                  <textarea v-model="postProductData.other_meta_data" class="form-control"
                            id="otherMetaData"
                            placeholder="Other Meta data should be also with meta tag">
                  </textarea>
                  <div class="text-danger" v-if="errors.otherMetaData">{{ errors.otherMetaData }}</div>
                </div>
              </div>
            </div>
            <section class="users-list-wrapper">
              <h5 class="my-1 text-uppercase"> Price & Discount </h5>
              <div class="row pb-2">
                <div class="col-lg-4 col-xl-2">
                  <ul class="px-0 bg-white">
                    <li v-for="(singleState, stateIndex) in stateList"
                        :key="stateIndex" class="d-block py-1 pl-1 state-side-bar-nav-item"
                        :class="[selectedState.value === singleState.value ? 'div-exact-active' : '']">
                      <div @click="selectedState = singleState" class="d-flex align-items-center">
                        <span> {{ singleState.name }} </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card col-lg-8 col-xl-10 pb-2">

                  <hr class="py-0 mt-0 mb-0">
                  <div v-for="(productStatePriceList,stateIndex) in productPriceList" :key="stateIndex">
                    <div v-if="selectedState.value === productStatePriceList.stateValue" class="card-body col-12">
                      <div class="d-flex flex-column">
                        <h3 class="text-center" style="font-size: 20px;"> {{ productStatePriceList.stateName }}</h3>
                        <div
                            class="custom-control custom-switch custom-control-inline align-items-center justify-content-center">
                          <div>
                            <input v-model="productStatePriceList.stateStatus" type="checkbox"
                                   class="custom-control-input" checked :id="`stateStatus-${stateIndex}`">
                            <label class="custom-control-label mr-1" :for="`stateStatus-${stateIndex}`"></label>
                          </div>
                          <span
                              class="font-medium-1">
                            {{ productStatePriceList.stateStatus ? "Active" : "Inactive" }}
                          </span>
                        </div>
                      </div>
                      <div v-if="productStatePriceList.stateStatus" class="mt-1">
                        <div v-for="(stateConditionWisePrice, stateConditionWisePriceIndex) in productStatePriceList.stateConditionWisePriceList"
                             :key="stateConditionWisePriceIndex">
                          <div class="row d-flex justify-content-between rounded py-1 my-1"
                               :style="stateConditionWisePriceRowColourStyle[stateConditionWisePriceIndex]">
                            <h6 class="ml-1">{{ stateConditionWisePrice.conditionName }}</h6>
                            <div class="custom-control custom-switch custom-control-inline  d-flex align-items-center">
                              <div>
                                <input v-model="stateConditionWisePrice.status" type="checkbox" class="custom-control-input" checked
                                       :id="`stateConditionWisePriceStatusCheckbox-${stateIndex}-${stateConditionWisePriceIndex}`">
                                <label class="custom-control-label mr-1"
                                       :for="`stateConditionWisePriceStatusCheckbox-${stateIndex}-${stateConditionWisePriceIndex}`">
                                </label>
                              </div>
                              <span class="font-medium-1">{{ stateConditionWisePrice.status ? 'Active' : 'Inactive' }}</span>
                            </div>
                          </div>

                          <div v-if="stateConditionWisePrice.status" class="row">
                            <div class="col-12 col-sm-12 col-md-3">
                              <div class="form-group">
                                <label>Price Amount</label>
                                <input v-model.number="stateConditionWisePrice.priceAmount" type="number" class="form-control"
                                       placeholder="Enter Price amount"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3">
                              <div class="custom-control custom-switch custom-control-inline align-items-center toggle-btn">
                                <div>
                                  <input v-model="stateConditionWisePrice.discountStatus" type="checkbox"
                                         class="custom-control-input" checked
                                         :id="`stateConditionWisePriceDiscountCheckbox-${stateIndex}-${stateConditionWisePriceIndex}`">
                                  <label class="custom-control-label mr-1"
                                         :for="`stateConditionWisePriceDiscountCheckbox-${stateIndex}-${stateConditionWisePriceIndex}`">
                                  </label>
                                </div>
                                <span class="font-medium-1">
                                  {{ stateConditionWisePrice.discountStatus ? "Has Discount" : "No Discount" }}
                                </span>
                              </div>
                            </div>
                            <div v-if="stateConditionWisePrice.discountStatus" class="col-12 col-sm-12 col-md-3">
                              <div class="form-group">
                                <label>Discount</label>
                                <input v-model="stateConditionWisePrice.discountAmount" type="number" class="form-control"
                                       placeholder="Enter discount Amount"/>
                              </div>
                            </div>
                            <div v-if="stateConditionWisePrice.discountStatus" class="col-12 col-sm-12 col-md-3">
                              <div class="form-group">
                                <div
                                    class="custom-control custom-switch custom-control-inline align-items-center toggle-btn">
                                  <div>
                                    <input v-model="stateConditionWisePrice.discountType" type="checkbox"
                                           class="custom-control-input" checked
                                           :id="`stateConditionWisePriceDiscountType-${stateIndex}-${stateConditionWisePriceIndex}`">
                                    <label class="custom-control-label mr-1"
                                           :for="`stateConditionWisePriceDiscountType-${stateIndex}-${stateConditionWisePriceIndex}`"></label>
                                  </div>
                                  <span class="font-medium-1">
                                        {{stateConditionWisePrice.discountType ? "Fixed Amount" : "Percentage" }}
                                      </span>
                                </div>
                              </div>
                            </div>
                            <div v-if="stateConditionWisePrice.discountStatus" class="col-12 col-sm-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label>Discount Start At</label>
                                  <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="d-flex align-items-center">
                                      <i class='bx bx-calendar'></i>
                                    </div>
                                    <div class="w-100 pl-1">
                                      <div class="w-100 ">
                                        <DatePicker v-model="stateConditionWisePrice.discountStartAt" :update-on-input="true"
                                                    mode="dateTime"
                                                    :min-date="new Date()"
                                                    :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }">
                                          <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                                          </template>
                                        </DatePicker>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div v-if="stateConditionWisePrice.discountStatus" class="col-12 col-sm-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label>Discount End At</label>
                                  <div class="d-flex  align-items-center date-picker-wrapper">
                                    <div class="d-flex align-items-center">
                                      <i class='bx bx-calendar'></i>
                                    </div>
                                    <div class="w-100 pl-1">
                                      <div class="w-100 ">
                                        <DatePicker v-model="stateConditionWisePrice.discountEndAt" :update-on-input="true"
                                                    mode="dateTime"
                                                    :min-date="new Date()"
                                                    :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }">
                                          <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                                          </template>
                                        </DatePicker>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <button @click="createSingleProduct"
                          type="button" class="btn btn-primary update-todo px-4">Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
// mixins
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {mapActions, mapGetters} from 'vuex';
// 3rd party packages
import {DatePicker} from 'v-calendar';
import VueMultiselect from 'vue-multiselect'
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "ProductCreate",

  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,
    DatePicker,
  },

  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      selectedSource: {
        value: 0,
        name: "Default",
      },
      getSettingsParams: {
        type: ['default', 'product_item'],
        key: ['default_state', 'product_item_condition'],
      },
      getProductCategoriesParams: {
        status: 1,
      },

      postProductData: {
        added_by: '',
        product_category_id: '',
        type: '',
        name: '',
        description: '',
        meta_description: '',
        meta_keywords: '',
        meta_author: '',
        other_meta_data: '',
        status: 1,
        source: '',
        pre_order: 1,
        return_appointment_status: 0,
        customer_portal_status: 1,
      },

      errors: {
        productCategoryId: '',
        type: '',
        name: '',
        description: '',
        metaDescription: '',
        metaKeywords: '',
        metaAuthor: '',
        otherMetaData: '',
        status: '',
        preOrder: '',
        returnAppointmentStatus: '',
        customerPortalStatus: ''
      },

      selectedProductCategory: {
        value: '',
        name: 'None'
      },

      selectedType: {
        value: '',
        name: 'None'
      },

      productStatus: true,

      productPreOrder: true,

      productReturnAppointmentStatus: false,

      productCustomerPortalStatus: true,

      selectedState: {
        name: '',
        value: '',
      },

      productPriceList: [],
      productId: '',
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      product: 'appProducts/product',
      authUser: 'appAuthLogin/authUser'
    }),

    productCategoryOptions() {
      let productCategories = this.$store.getters['appProductCategories/productCategories'].map((productCategory) => {
        let id = productCategory.id;
        let name = productCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...productCategories];
    },

    typeOptions() {
      return [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Hardware'
        },
        {
          value: 1,
          name: 'Software'
        },
      ];
    },

    statusOptions() {
      return [
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },

    stateList() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    productItemConditions() {
      return this.$store.getters['appSettings/settingProductItemCondition'].value;
    },

    stateConditionWisePriceRowColourStyle() {
      return [
        {
          backgroundColor: '#ecf4ff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fdf4e9',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#f1fff1',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
      ];
    },

    postListProductPriceData() {
      let priceList = [];
      this.productPriceList.forEach(productStatePriceList => {
        productStatePriceList.stateConditionWisePriceList.forEach(stateConditionWisePrice => {
          let price = {
            state: productStatePriceList.stateValue,
            condition: stateConditionWisePrice.conditionValue,
            amount: stateConditionWisePrice.priceAmount * 100,
            discount: stateConditionWisePrice.discountType ? stateConditionWisePrice.discountAmount * 100 : stateConditionWisePrice.discountAmount,
            discount_type: stateConditionWisePrice.discountType ? 1 : 0,
            status: stateConditionWisePrice.status ? 1 : 0,
            discount_status: stateConditionWisePrice.discountStatus ? 1 : 0,
            discount_start_at: stateConditionWisePrice.discountStartAt ? stateConditionWisePrice.discountStartAt : null,
            discount_end_at: stateConditionWisePrice.discountEndAt ? stateConditionWisePrice.discountEndAt : null,
          }
          priceList.push(price);
        });
      });
      return priceList;
    },
    sourceOption() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [{value: 0, name: "Default"}, ...sources];
    },

    redirectRouteName() {
      return this.can('product-view-any') ? 'appProductList' : 'appProductCreate';
    },
  },
  watch: {
    selectedProductCategory(selectedProductCategory) {
      this.postProductData.product_category_id = selectedProductCategory.value;
    },

    selectedType(selectedType) {
      this.postProductData.type = selectedType.value;
    },

    productStatus(productStatus) {
      this.postProductData.status = productStatus ? 1 : 0;
    },

    productPreOrder(preOrder) {
      this.postProductData.pre_order = preOrder ? 1 : 0;
    },
    productReturnAppointmentStatus(returnAppointmentStatus) {
      this.postProductData.return_appointment_status = returnAppointmentStatus ? 1 : 0;
    },
    productCustomerPortalStatus(customerPortalStatus) {
      this.postProductData.customer_portal_status = customerPortalStatus ? 1 : 0;
    },
    selectedSource(source) {
      this.postProductData.source = source.value;
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getProductCategories: 'appProductCategories/getProductCategories',
      postProduct: 'appProducts/postProduct',
      postListProductPrice: 'appProductPrices/postListProductPrice',
      getReferral: "appReferrals/getReferrals",
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getProductCategoryList() {
      await this.getProductCategories(this.getProductCategoriesParams);
    },

    async buildProductPriceList() {
      let productStatePriceList = [];

      await this.stateList.forEach(singleState => {
        let conditionWisePriceList = [];

        this.productItemConditions.forEach(itemCondition => {
          let conditionWisePrice = {
            state: singleState.value,
            conditionName: itemCondition.name,
            conditionValue: itemCondition.value,
            priceAmount: 0,
            discountAmount: 0,
            discountType: false,
            discountStatus: false,
            discountStartAt: null,
            discountEndAt: null,
            status: false,
          };
          conditionWisePriceList.push(conditionWisePrice);
        });

        let productSingleStatePriceData = {
          stateName: singleState.name,
          stateValue: singleState.value,
          stateStatus: false,
          stateConditionWisePriceList: conditionWisePriceList,
        };
        productStatePriceList.push(productSingleStatePriceData);
      });

      return productStatePriceList;
    },

    async createSingleProduct() {
      this.postProductData.added_by = await this.authUser.id;
      await this.loader(true);
      await this.postProduct(this.postProductData).then(async (response) => {
        await this.loader(false);
        if (response.status === 201) {
          this.productId = this.product.id;
          await this.createListProductPrice();
          const toastObj = {
            message: "Product Created Successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.productCategoryId = response.errors && response.errors.product_category_id ? response.errors.product_category_id[0] : '';
        this.errors.type = response.errors && response.errors.type ? response.errors.type[0] : '';
        this.errors.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        this.errors.description = response.errors && response.errors.description ? response.errors.description[0] : '';
        this.errors.metaDescription = response.errors && response.errors.meta_description ? response.errors.meta_description[0] : '';
        this.errors.metaKeywords = response.errors && response.errors.meta_keywords ? response.errors.meta_keywords[0] : '';
        this.errors.metaAuthor = response.errors && response.errors.meta_author ? response.errors.meta_author[0] : '';
        this.errors.otherMetaData = response.errors && response.errors.other_meta_data ? response.errors.other_meta_data[0] : '';
        this.errors.status = response.errors && response.errors.status ? response.errors.status[0] : '';
        this.errors.preOrder = response.errors && response.errors.pre_order ? response.errors.pre_order[0] : '';
        this.errors.returnAppointmentStatus = response.errors && response.errors.return_appointment_status ? response.errors.return_appointment_status[0] : '';
        this.errors.customerPortalStatus = response.errors && response.errors.customer_portal_status ? response.errors.customer_portal_status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      });
    },

    async createListProductPrice() {
      let dataObj = {
        product_id: this.productId,
        prices: JSON.stringify(this.postListProductPriceData),
      }

      await this.postListProductPrice(dataObj).then(async (response) => {
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getProductCategoryList();
    await this.getReferral();
    this.productPriceList = await this.buildProductPriceList();
    this.selectedState = await this.stateList[0];
    await this.loader(false);
  }
}
</script>

<style scoped>
.toggle-btn {
  margin-top: 2rem;
}

h6 {
  color: #475F7B !important;
}

.icon-custom-font-size {
  font-size: 20px;
}

ul {
  text-decoration: none;
  list-style: none;
}

.div-exact-active {
  background-color: var(--primary-color);
  color: white !important;

}

.div-exact-active:hover {
  color: white !important;

}

.state-side-bar-nav-item:hover {
  color: var(--primary-color);
}

.state-side-bar-nav-item {
  cursor: pointer;
  color: #475f7b;
}

.multiselect__tags:focus-within {

  /* border:1px solid red !important; */
  border-color: #5A8DEE;

}

.multiselect__tags {
  min-height: 0;
  max-height: 38px !important;
  border-color: #DFE3E7;
}

.ql-editor {
  min-height: 200px !important;
}

.state {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;


}

.state-name {
  flex-basis: 4%;
}

.state-price {
  flex-basis: 32%;
}

@media only screen and (max-width: 600px) {
  .state {
    display: flex;
    flex-direction: column !important;
    gap: 20px;
    align-items: stretch !important;

  }

  .state-name {
    flex-basis: 100% !important;
    align-self: center !important;
  }

  .state-price {
    flex-basis: 100% !important;
  }

}

@media only screen and (max-width: 768px) {
  .toggle-btn {
    margin-top: 0;
  }

}
</style>